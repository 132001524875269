jQuery(document).ready(function() {
var $container = $('ul.posts-items');
// initialize Masonry after all images have loaded  
$container.imagesLoaded( function() {
  $container.masonry({
  	itemSelector: '.post-item',
    percentPosition: true

  });
});

});
